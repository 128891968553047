import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTranslations,
  Grid,
  Button,
  DialogActions,
  Typography,
  Box,
  ReactHookFormAutocomplete,
  ReactHookFormCheckboxGroup,
  ReactHookFormSlider,
  getCurrentTimeZone,
} from '@uniqkey-frontend/shared-app';
import {
  GetTimeOfDayGroupRestrictionByIdResponse,
} from '@uniqkey-backend-organization-web/api-client';
import timezones, { ITimezone } from '../../../../helpers/restrictions/timezones';
import {
  RestrictionDayAsFlagEnum,
  RestrictionDayNameEnum,
} from '../../../../enums/RestrictionDayEnum';
import { convertStringToTime, parseTimeRestriction } from '../../../../helpers/restrictions';

interface ITimeRestrictionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (params: ICreateTimeRestrictionModalReturnValue) => Promise<void>;
  onEdit: (params: IEditTimeRestrictionModalReturnValue) => Promise<void>;
  isLoading: boolean;
  restrictionToUpdate: GetTimeOfDayGroupRestrictionByIdResponse | null;
}

interface IFormValue {
  days: RestrictionDayAsFlagEnum[];
  timezone: ITimezone;
  time: number[];
}

export interface ICreateTimeRestrictionModalReturnValue extends IFormValue {}

export interface IEditTimeRestrictionModalReturnValue extends IFormValue {
  groupRestrictionId: string;
}

const DEFAULT_TIME = [9, 16.5];
const MARKS = [{ value: 0, label: '00:00' }, { value: 24, label: '24:00' }];
const getValueLabel = (val: number) => (val % 1 === 0
  ? `${(`0${val}`).slice(-2)}:00`
  : `${(`0${Math.floor(val)}`).slice(-2)}:30`
);

const RESTRICTIONS_DAYS_SHORT_TRANSLATION_KEYS = {
  [RestrictionDayNameEnum.Monday]: 'restrictions.days.short.monday',
  [RestrictionDayNameEnum.Tuesday]: 'restrictions.days.short.tuesday',
  [RestrictionDayNameEnum.Wednesday]: 'restrictions.days.short.wednesday',
  [RestrictionDayNameEnum.Thursday]: 'restrictions.days.short.thursday',
  [RestrictionDayNameEnum.Friday]: 'restrictions.days.short.friday',
  [RestrictionDayNameEnum.Saturday]: 'restrictions.days.short.saturday',
  [RestrictionDayNameEnum.Sunday]: 'restrictions.days.short.sunday',
};

const TimeRestrictionModal = (props: ITimeRestrictionModalProps) => {
  const {
    isOpen, onClose, onCreate, onEdit, isLoading, restrictionToUpdate,
  } = props;
  const defaultTimezone = useMemo(() => {
    const currentTimezone = getCurrentTimeZone();
    const currentTimezoneInfo = timezones.find(
      (timezone) => timezone.name === currentTimezone,
    );
    if (currentTimezoneInfo) {
      return currentTimezoneInfo;
    }
    return timezones.find(
      (timezone) => timezone.name === 'Europe/Copenhagen',
    );
  }, []); // should be inside the component to get the actual timezone

  const { t } = useTranslations();
  const isEditMode = !!restrictionToUpdate;
  const defaultValues = useMemo(() => {
    if (isEditMode) {
      const {
        offset, offsetName, allowedFromTime, allowedToTime, dayOfWeek,
      } = restrictionToUpdate;
      return ({
        days: parseTimeRestriction(dayOfWeek),
        timezone: { name: offsetName, offset },
        time: [
          convertStringToTime(allowedFromTime),
          convertStringToTime(allowedToTime),
        ],
      });
    }
    return { days: [], timezone: defaultTimezone, time: DEFAULT_TIME };
  }, [isEditMode, restrictionToUpdate, defaultTimezone]);

  const {
    handleSubmit, control, formState: { errors, isDirty },
  } = useForm<IFormValue>({ defaultValues, mode: 'all' });
  const handleGetOptionLabel = useCallback((option: ITimezone) => {
    const { name } = option;
    return name;
  }, []);

  const dayRestrictions = [
    {
      dataFlag: RestrictionDayAsFlagEnum.Monday,
      dataName: t(RESTRICTIONS_DAYS_SHORT_TRANSLATION_KEYS[RestrictionDayNameEnum.Monday]),
    },
    {
      dataFlag: RestrictionDayAsFlagEnum.Tuesday,
      dataName: t(RESTRICTIONS_DAYS_SHORT_TRANSLATION_KEYS[RestrictionDayNameEnum.Tuesday]),
    },
    {
      dataFlag: RestrictionDayAsFlagEnum.Wednesday,
      dataName: t(RESTRICTIONS_DAYS_SHORT_TRANSLATION_KEYS[RestrictionDayNameEnum.Wednesday]),
    },
    {
      dataFlag: RestrictionDayAsFlagEnum.Thursday,
      dataName: t(RESTRICTIONS_DAYS_SHORT_TRANSLATION_KEYS[RestrictionDayNameEnum.Thursday]),
    },
    {
      dataFlag: RestrictionDayAsFlagEnum.Friday,
      dataName: t(RESTRICTIONS_DAYS_SHORT_TRANSLATION_KEYS[RestrictionDayNameEnum.Friday]),
    },
    {
      dataFlag: RestrictionDayAsFlagEnum.Saturday,
      dataName: t(RESTRICTIONS_DAYS_SHORT_TRANSLATION_KEYS[RestrictionDayNameEnum.Saturday]),
    },
    {
      dataFlag: RestrictionDayAsFlagEnum.Sunday,
      dataName: t(RESTRICTIONS_DAYS_SHORT_TRANSLATION_KEYS[RestrictionDayNameEnum.Sunday]),
    },
  ];
  const [{ value: minMarkValue }, { value: maxMarkValue }] = MARKS;
  const handleCreateOrEdit = useCallback((value: IFormValue) => {
    if (isEditMode) {
      const { groupRestrictionId } = restrictionToUpdate;
      return onEdit({ ...value, groupRestrictionId });
    }
    return onCreate(value);
  }, [isEditMode, restrictionToUpdate, onEdit, onCreate]);

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(handleCreateOrEdit)}>
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {isEditMode
            ? t('timeRestrictionsModal.title.edit')
            : t('timeRestrictionsModal.title.create')}
        </DialogTitle>
        <DialogContent>
          <Typography variant="caption">{`${t('timeRestrictionsModal.chooseDays')}*`}</Typography>
          <Grid container columnSpacing={1.5} justifyContent="center">
            <ReactHookFormCheckboxGroup
              data={dayRestrictions}
              name="days"
              control={control}
              rules={{ required: t('timeRestrictionsModal.atLeastOneData') }}
              errorMessage={errors.days?.message}
            />
          </Grid>
          <Typography variant="caption">{t('timeRestrictionsModal.chooseTimeInterval')}</Typography>
          <Box m={2} mt={4}>
            <ReactHookFormSlider
              name="time"
              control={control}
              aria-labelledby="range-slider"
              valueLabelDisplay="on"
              step={0.5}
              min={minMarkValue}
              max={maxMarkValue}
              marks={MARKS}
              valueLabelFormat={getValueLabel}
            />
          </Box>
          <Typography variant="caption">{`${t('timeRestrictionsModal.timezone')}*`}</Typography>
          <ReactHookFormAutocomplete
            name="timezone"
            options={timezones}
            control={control}
            getOptionLabel={handleGetOptionLabel}
            rules={{ required: t('validation.required') }}
            optionKeyField="name"
          />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button fullWidth isLoading={isLoading} disabled={!isDirty} type="submit">
                {isEditMode ? t('common.save') : t('common.create')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(TimeRestrictionModal);
