import {
  useCallback, useMemo, useState, memo,
} from 'react';
import {
  ActionButton,
  Box,
  Button,
  Divider,
  Grid,
  InviteUserIcon,
  PanelContent,
  SearchableTextField,
  FilterButton,
  Tooltip,
  usePopper,
  useSnackbar,
  useTranslations,
  usePubSub,
  ErrorIcon,
  useMapKeyValueExtractor,
  PromptModal,
  MessageIcon,
  ArchiveIcon,
  DeleteIcon,
  RecoverIcon,
  ISearchableTextFieldProps,
  IFilterButtonProps,
} from '@uniqkey-frontend/shared-app';
import {
  ArchiveBulkEmployeeAccountErrorDetails, EmployeeAccountStatus,
} from '@uniqkey-backend-organization-web/api-client';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import {
  useDeleteEmployeeAccounts,
  useDetachBulkEmployeeAccountsToGroups,
  useGetGroupById,
  useGetOrganizationScimSettings,
} from '../../../../hooks/reactQuery';
import useEmployeesTable from '../../../../hooks/tables/useEmployeesTable';
import EmployeesTable, { IEmployeesTableRow } from '../../../../components/tables/EmployeesTable';
import EmployeesListFilter, {
  IEmployeesListFilterSubmitResult,
} from '../../../EmployeesPage/components/EmployeesListFilter';
import EmployeesSelectorModal from '../../../GroupsPage/components/EmployeesSelectorModal';
import { generateTooltipTitle } from '../../../../helpers/tooltips';
import SendInviteModal from '../../../EmployeesPage/components/SendInviteModal';
import { logException } from '../../../../services/sentryService';
import useEmployeeAccountsAPI from '../../../../hooks/useEmployeeAccountsAPI';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import useMigrationAPI from '../../../../hooks/useMigrationAPI';
import useFilteredEntityIds from '../../../../hooks/useFilteredEntityIds';

interface IGroupMembersTabProps {
  groupId: string;
}

const GroupMembersTab = (props: IGroupMembersTabProps) => {
  const { groupId } = props;
  const [isEmployeesSelectorModalOpen, setIsEmployeesSelectorModalOpen] = useState<boolean>(false);
  const [isRemoveEmployeeModalOpen, setIsRemoveEmployeeModalOpen] = useState<boolean>(false);
  const [isRemoveEmployeeLoading, setIsRemoveEmployeeLoading] = useState<boolean>(false);
  const [isSendInviteModalOpen, setIsSendInviteModalOpen] = useState<boolean>(false);
  const [isSendInviteLoading, setIsSendInviteLoading] = useState<boolean>(false);
  const [isArchiveEmployeeModalOpen, setIsArchiveEmployeeModalOpen] = useState<boolean>(false);
  const [isArchiveEmployeeLoading, setIsArchiveEmployeeLoading] = useState<boolean>(false);
  const [isDeleteEmployeeModalOpen, setIsDeleteEmployeeModalOpen] = useState<boolean>(false);
  const [isDeleteEmployeeLoading, setIsDeleteEmployeeLoading] = useState<boolean>(false);
  const [isRestoreEmployeeModalOpen, setIsRestoreEmployeeModalOpen] = useState<boolean>(false);
  const [isRestoreEmployeeLoading, setIsRestoreEmployeeLoading] = useState<boolean>(false);

  const { t } = useTranslations();
  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const { showError, showSuccess, showWarning } = useSnackbar();

  const { mutate: mutateRemove } = useDetachBulkEmployeeAccountsToGroups();
  const { mutate: mutateDelete } = useDeleteEmployeeAccounts();
  const {
    archiveEmployeeAccounts, sendInviteBulk, restoreEmployeeAccounts,
  } = useEmployeeAccountsAPI();
  const { sendInviteBulk: sendInviteMigratedBulk } = useMigrationAPI();

  const { data: group, isLoading: isGetGroupByIdLoading } = useGetGroupById(
    { groupId },
  );

  const {
    data: scim,
    isLoading: isGetOrganizationScimSettingsLoading,
    isError: isGetOrganizationScimSettingsError,
  } = useGetOrganizationScimSettings({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });
  const { secretToken } = scim ?? {};

  const {
    selectedEmployees,
    refetch,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    isLoading: areEmployeesLoading,
    ...restTableProps
  } = useEmployeesTable({
    persistentFilters: { groupId },
    noDataMessageKey: 'table.noData.default',
  });

  const {
    values: selectedEmployeesAsObjects, keys: selectedEmployeesIds,
  } = useMapKeyValueExtractor<IEmployeesTableRow>(selectedEmployees);

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const handleEmployeesSelectorModalOpen = useCallback(
    () => setIsEmployeesSelectorModalOpen(true),
    [],
  );
  const handleEmployeesSelectorModalClose = useCallback(
    () => setIsEmployeesSelectorModalOpen(false),
    [],
  );
  const handleRemoveEmployeeModalOpen = useCallback(() => setIsRemoveEmployeeModalOpen(true), []);
  const handleRemoveEmployeeModalClose = useCallback(() => setIsRemoveEmployeeModalOpen(false), []);

  const handleRefetch = useCallback(() => refetch(true), [refetch]);
  usePubSub(PubSubEventEnum.DATA_SYNCHRONIZATION_EMPLOYEE, handleRefetch);

  const handleRemoveEmployee = useCallback(async () => {
    setIsRemoveEmployeeLoading(true);
    const requests = selectedEmployeesIds.map((employeeAccountId) => (
      { employeeAccountId, groupId: group!.groupId }
    ));
    mutateRemove({ requests }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t(
              'deleteEmployeesFromGroupModal.successMessage',
              { count: successCount, name: group!.name },
            ),
          });
        }
        if (failCount) {
          showError({
            text: t('deleteEmployeesFromGroupModal.errorMessage', { count: failCount }),
          });
        }
        handleRemoveEmployeeModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: () => showError({ text: t('common.somethingWentWrong') }),
      onSettled: () => setIsRemoveEmployeeLoading(false),
    });
  }, [
    group,
    selectedEmployeesIds,
    mutateRemove,
    handleRemoveEmployeeModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );
  const handleFilterSubmit = useCallback((updatedValues: IEmployeesListFilterSubmitResult) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const handleSendInviteModalOpen = useCallback(() => setIsSendInviteModalOpen(true), []);
  const handleSendInviteModalClose = useCallback(() => setIsSendInviteModalOpen(false), []);
  const handleEmployeeArchiveModalOpen = useCallback(() => setIsArchiveEmployeeModalOpen(true), []);
  const handleEmployeeArchiveModalClose = useCallback(
    () => setIsArchiveEmployeeModalOpen(false),
    [],
  );
  const handleEmployeeDeleteModalOpen = useCallback(() => setIsDeleteEmployeeModalOpen(true), []);
  const handleEmployeeDeleteModalClose = useCallback(() => setIsDeleteEmployeeModalOpen(false), []);
  const handleEmployeeRestoreModalOpen = useCallback(() => setIsRestoreEmployeeModalOpen(true), []);
  const handleEmployeeRestoreModalClose = useCallback(
    () => setIsRestoreEmployeeModalOpen(false),
    [],
  );

  const stagedAndInvitedEmployeesIds = useFilteredEntityIds<
    IEmployeesTableRow, EmployeeAccountStatus
  >({
    entities: selectedEmployeesAsObjects,
    values: [EmployeeAccountStatus.Staged, EmployeeAccountStatus.Invited],
    filterByFieldName: 'employeeAccountStatus',
    idFieldName: 'employeeAccountId',
  });

  const migratedEmployeesIds = useFilteredEntityIds<
    IEmployeesTableRow, EmployeeAccountStatus
  >({
    entities: selectedEmployeesAsObjects,
    values: [EmployeeAccountStatus.Migrated],
    filterByFieldName: 'employeeAccountStatus',
    idFieldName: 'employeeAccountId',
  });

  const handleSendInvite = useCallback(async (language: string) => {
    setIsSendInviteLoading(true);
    try {
      const [responseStagedAndInvited, responseMigrated] = await Promise.all(
        [
          stagedAndInvitedEmployeesIds.length > 0
            ? sendInviteBulk({ language, employeeAccountIds: stagedAndInvitedEmployeesIds })
            : null,
          migratedEmployeesIds.length > 0
            ? sendInviteMigratedBulk(migratedEmployeesIds)
            : null,
        ],
      );
      const succeededCount = (responseStagedAndInvited?.succeededCount || 0)
        + (responseMigrated?.successCount || 0);
      const failedCount = (responseStagedAndInvited?.failedCount || 0)
        + (responseMigrated?.failCount || 0);
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.invitationEmailCreated'),
        });
      } else {
        if (responseMigrated && !responseStagedAndInvited) { // only migrated are selected
          showWarning({
            text: t('sendInviteModal.successMessageForMigratedUsers', { count: succeededCount }),
          });
        } else { // selected invited, staged and (optionally) migrated
          showWarning({
            text: t('sendInviteModal.successMessage', { count: succeededCount }),
          });
        }
        if (failedCount) {
          const totalCount = succeededCount + failedCount;
          showError({
            text: t('sendInviteModal.errorMessage', { failedCount, totalCount }),
          });
        }
      }
      handleSendInviteModalClose();
      resetSelectedRows();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupMembersTab/handleSendInvite exception',
      });
    } finally {
      setIsSendInviteLoading(false);
    }
  }, [
    stagedAndInvitedEmployeesIds,
    migratedEmployeesIds,
    sendInviteMigratedBulk,
    isTrustedPortalEnabled,
    sendInviteBulk,
    handleSendInviteModalClose,
    resetSelectedRows,
    showSuccess,
    showError,
    showWarning,
    t,
  ]);

  const handleArchiveEmployee = useCallback(async () => {
    setIsArchiveEmployeeLoading(true);
    try {
      const {
        failCount,
        successCount,
        archiveBulkEmployeeAccountDetails,
      } = await archiveEmployeeAccounts({ employeeAccountIds: selectedEmployeesIds });
      if (successCount) {
        showSuccess({
          text: t('archiveEmployeeModal.successMessage', { count: successCount }),
        });
      }
      if (failCount) {
        showError({
          text: t('archiveEmployeeModal.errorMessage', { count: failCount }),
        });
      }
      const lastAdminError = archiveBulkEmployeeAccountDetails.find(
        (el) => el.errorDetails === ArchiveBulkEmployeeAccountErrorDetails.IsCurrentAdmin,
      );
      if (lastAdminError) {
        showError({
          text: t(
            'archiveEmployeeModal.cannotBeRemovedErrorMessage',
            { email: lastAdminError.email },
          ),
        });
      }
      handleEmployeeArchiveModalClose();
      resetSelectedRows();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupMembersTab/handleArchiveEmployee exception',
      });
    } finally {
      setIsArchiveEmployeeLoading(false);
    }
  }, [
    selectedEmployeesIds,
    archiveEmployeeAccounts,
    handleEmployeeArchiveModalClose,
    resetSelectedRows,
    showError,
    showSuccess,
    t,
  ]);

  const handleDeleteEmployee = useCallback(async () => {
    setIsDeleteEmployeeLoading(true);
    mutateDelete({ employeeAccountIds: selectedEmployeesIds }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t('deleteEmployeeModal.successMessage', { count: successCount }),
          });
        }
        if (failCount) {
          showError({
            text: t('deleteEmployeeModal.errorMessage', { count: failCount }),
          });
        }
        handleEmployeeDeleteModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: () => showError({ text: t('common.somethingWentWrong') }),
      onSettled: () => setIsDeleteEmployeeLoading(false),
    });
  }, [
    selectedEmployeesIds,
    mutateDelete,
    handleEmployeeDeleteModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  const handleRestoreEmployee = useCallback(async () => {
    setIsRestoreEmployeeLoading(true);
    try {
      const {
        failCount, successCount,
      } = await restoreEmployeeAccounts({ employeeAccountIds: selectedEmployeesIds });
      if (successCount) {
        showSuccess({
          text: t('restoreEmployeeModal.successMessage', { count: successCount }),
        });
      }
      if (failCount) {
        showError({
          text: t('restoreEmployeeModal.errorMessage', { count: failCount }),
        });
      }
      handleEmployeeRestoreModalClose();
      resetSelectedRows();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupMembersTab/handleRestoreEmployee exception',
      });
    } finally {
      setIsRestoreEmployeeLoading(false);
    }
  }, [
    selectedEmployeesIds,
    restoreEmployeeAccounts,
    handleEmployeeRestoreModalClose,
    resetSelectedRows,
    showError,
    showSuccess,
    t,
  ]);

  const {
    isSendInviteDisabled,
    isArchiveDisabled,
    isDeleteDisabled,
    isRestoreDisabled,
  } = useMemo(() => {
    if (!selectedEmployeesAsObjects.length) {
      return {
        isSendInviteDisabled: true,
        isArchiveDisabled: true,
        isDeleteDisabled: true,
        isRestoreDisabled: true,
      };
    }
    let disableSendInvite = false;
    let disableArchive = false;
    let disableDelete = false;
    let disableRestore = false;
    selectedEmployeesAsObjects.forEach(({ employeeAccountStatus, isByScim }) => {
      const shouldDisableAction = isByScim && !!secretToken;
      if (employeeAccountStatus === EmployeeAccountStatus.Archived) {
        if (shouldDisableAction) {
          disableRestore = true;
        }
        disableArchive = true;
        disableSendInvite = true;
      }
      if (employeeAccountStatus === EmployeeAccountStatus.Active) {
        if (shouldDisableAction) {
          disableArchive = true;
        }
        disableSendInvite = true;
        disableDelete = true;
        disableRestore = true;
      }
      if (employeeAccountStatus === EmployeeAccountStatus.Invited) {
        if (shouldDisableAction) {
          disableDelete = true;
        }
        disableArchive = true;
        disableRestore = true;
      }
      if (employeeAccountStatus === EmployeeAccountStatus.Staged) {
        disableArchive = true;
        disableRestore = true;
      }
      if (employeeAccountStatus === EmployeeAccountStatus.Unprocessed) {
        disableArchive = true;
        disableSendInvite = true;
        disableRestore = true;
      }
      if (employeeAccountStatus === EmployeeAccountStatus.ExistingUnprocessed) {
        disableArchive = true;
        disableSendInvite = true;
        disableRestore = true;
      }
    });
    return {
      isSendInviteDisabled: disableSendInvite,
      isArchiveDisabled: disableArchive,
      isDeleteDisabled: disableDelete,
      isRestoreDisabled: disableRestore,
    };
  }, [secretToken, selectedEmployeesAsObjects]);

  const {
    removeTooltipTitle,
    sendInviteTooltipTitle,
    archiveTooltipTitle,
    deleteTooltipTitle,
    restoreTooltipTitle,
  } = useMemo(() => {
    const removeTitle = generateTooltipTitle({
      selectedDataLength: selectedEmployeesAsObjects.length,
      t,
      isDisabled: false,
      key: 'groupPage.membersTab.remove',
    });
    const sendInviteTitle = generateTooltipTitle({
      selectedDataLength: selectedEmployeesAsObjects.length,
      t,
      isDisabled: isSendInviteDisabled,
      key: 'groupPage.membersTab.sendInvite',
    });
    const archiveTitle = generateTooltipTitle({
      selectedDataLength: selectedEmployeesAsObjects.length,
      t,
      isDisabled: isArchiveDisabled,
      key: 'groupPage.membersTab.archive',
    });
    const deleteTitle = generateTooltipTitle({
      selectedDataLength: selectedEmployeesAsObjects.length,
      t,
      isDisabled: isDeleteDisabled,
      key: 'groupPage.membersTab.delete',
    });
    const restoreTitle = generateTooltipTitle({
      selectedDataLength: selectedEmployeesAsObjects.length,
      t,
      isDisabled: isRestoreDisabled,
      key: 'groupPage.membersTab.restore',
    });

    return {
      removeTooltipTitle: removeTitle,
      sendInviteTooltipTitle: sendInviteTitle,
      archiveTooltipTitle: archiveTitle,
      deleteTooltipTitle: deleteTitle,
      restoreTooltipTitle: restoreTitle,
    };
  }, [
    selectedEmployeesAsObjects.length,
    t,
    isSendInviteDisabled,
    isArchiveDisabled,
    isDeleteDisabled,
    isRestoreDisabled,
  ]);

  const employeesSelectorModalSelectedGroupsIds = useMemo(() => [groupId], [groupId]);

  if (isGetOrganizationScimSettingsError) {
    return null;
  }

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={removeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleRemoveEmployeeModalOpen}
                disabled={!selectedEmployees.size}
              >
                <ErrorIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={sendInviteTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleSendInviteModalOpen}
                disabled={isSendInviteDisabled}
              >
                <MessageIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={archiveTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleEmployeeArchiveModalOpen}
                disabled={isArchiveDisabled}
              >
                <ArchiveIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={deleteTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleEmployeeDeleteModalOpen}
                disabled={isDeleteDisabled}
              >
                <DeleteIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={restoreTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleEmployeeRestoreModalOpen}
                disabled={isRestoreDisabled}
              >
                <RecoverIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
          <Box mr={3} />
          <Grid>
            <Button
              disabled={isGetGroupByIdLoading}
              icon={<InviteUserIcon />}
              onClick={handleEmployeesSelectorModalOpen}
            >
              {t('groupPage.membersTab.addMembersButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <EmployeesTable
        selectedEmployees={selectedEmployees}
        isSecretTokenEmpty={!secretToken}
        isLoading={areEmployeesLoading || isGetOrganizationScimSettingsLoading}
        {...restTableProps}
      />
      <EmployeesListFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
      {isEmployeesSelectorModalOpen && (
        <EmployeesSelectorModal
          isOpen={isEmployeesSelectorModalOpen}
          onClose={handleEmployeesSelectorModalClose}
          groupsIds={employeesSelectorModalSelectedGroupsIds}
          groupName={group!.name}
        />
      )}
      {isRemoveEmployeeModalOpen && (
        <PromptModal
          open={isRemoveEmployeeModalOpen}
          onClose={handleRemoveEmployeeModalClose}
          onSubmit={handleRemoveEmployee}
          title={t(
            'deleteEmployeesFromGroupModal.title',
            {
              count: selectedEmployees.size,
              name: group!.name,
            },
          )}
          description={t('deleteEmployeesFromGroupModal.description')}
          list={selectedEmployeesAsObjects}
          renderField="email"
          renderKey="employeeAccountId"
          isLoading={isRemoveEmployeeLoading}
        />
      )}
      {isSendInviteModalOpen && (
        <SendInviteModal
          areStagedAndInvitedOnly={!!stagedAndInvitedEmployeesIds.length}
          isOpen={isSendInviteModalOpen}
          onClose={handleSendInviteModalClose}
          onApprove={handleSendInvite}
          isLoading={isSendInviteLoading}
        />
      )}
      {isArchiveEmployeeModalOpen && (
        <PromptModal
          open={isArchiveEmployeeModalOpen}
          onClose={handleEmployeeArchiveModalClose}
          onSubmit={handleArchiveEmployee}
          title={t('archiveEmployeeModal.title', { count: selectedEmployees.size })}
          description={t('archiveEmployeeModal.description')}
          list={selectedEmployeesAsObjects}
          renderField="email"
          renderKey="employeeAccountId"
          isLoading={isArchiveEmployeeLoading}
          approvalButtonText="common.archive"
        />
      )}
      {isDeleteEmployeeModalOpen && (
        <PromptModal
          open={isDeleteEmployeeModalOpen}
          onClose={handleEmployeeDeleteModalClose}
          onSubmit={handleDeleteEmployee}
          title={t('deleteEmployeeModal.title', { count: selectedEmployees.size })}
          description={t('deleteEmployeeModal.description')}
          list={selectedEmployeesAsObjects}
          renderField="email"
          renderKey="employeeAccountId"
          isLoading={isDeleteEmployeeLoading}
          approvalButtonText="common.delete"
        />
      )}
      {isRestoreEmployeeModalOpen && (
        <PromptModal
          open={isRestoreEmployeeModalOpen}
          onClose={handleEmployeeRestoreModalClose}
          onSubmit={handleRestoreEmployee}
          title={t('restoreEmployeeModal.title', { count: selectedEmployees.size })}
          description={t('restoreEmployeeModal.description')}
          list={selectedEmployeesAsObjects}
          renderField="email"
          renderKey="employeeAccountId"
          isLoading={isRestoreEmployeeLoading}
          approvalButtonText="common.restore"
          approvalButtonColor="primary"
        />
      )}
    </PanelContent>
  );
};

export default memo(GroupMembersTab);
